import React, { useState, useEffect, useCallback } from "react";
import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";
let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => { },
  logout: () => { },
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);
  const [toasts, setToasts] = useState({
    position: "top-right",
    autohide: 3000,
    show: false,
    fade: false,
    closeButton: true,
    msg: "",
    title: "",
  });
  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime) => {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const resetToaster = () => {
    setToasts({
      position: "top-right",
      autohide: 3000,
      show: false,
      fade: false,
      closeButton: true,
      msg: "",
      title: "",
      color: "success",
    });
  };

  const ToasterHandler = (data) => {
    resetToaster();
    setToasts({
      position: "top-right",
      autohide: 3000,
      show: true,
      fade: true,
      closeButton: true,
      msg: data.msg,
      title: data.title,
      color: data.color,
    });
    // setTimeout(() => {
    //   resetToaster();
    // }, 3000);
  };

  useEffect(() => {
    if (tokenData) {
      // console.log(tokenData.duration);
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    toaster: ToasterHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
      <CToaster position={toasts.position} key={"toaster"}>
        <CToast
          key={"toast"}
          show={toasts.show}
          autohide={toasts.autohide}
          fade={toasts.fade}
          color={toasts.color}
        >
          <CToastHeader closeButton={toasts.closeButton}>
            {toasts.title}
          </CToastHeader>
          <CToastBody>{toasts.msg}</CToastBody>
        </CToast>
      </CToaster>
    </AuthContext.Provider>
  );
};

export default AuthContext;
