import React, { useContext } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "./scss/style.scss";
import AuthContext from "./store/auth-context";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Welcome = React.lazy(() => import("./views/pages/welcome/Welcome"));
const App = () => {
  const authCtx = useContext(AuthContext);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {authCtx.isLoggedIn && (
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          )}

          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />

          <Route
            exact
            path="/shopify_dashboard"
            name="Shopify Dashboard"
            render={(props) => <Welcome {...props} />}
          />
          {!authCtx.isLoggedIn && <Redirect to="/login" />}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};
export default App;
